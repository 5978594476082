import './App.css';
import  crypto from 'crypto'

declare var ZoomMtg

ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.5/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {

  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  var apiKey = 'dzOf1gATQQGLMMAdBM72uQ'
  var meetingNumber = '71613988562'
  var role = 0
  var leaveUrl = 'https://virtuallive.in'
  var userName = 'React'
  var userEmail = 'thinlimbsworking@gmail.com'
  var passWord = 'angry123bird'
var apiSecret='VmU6tcF0kQy6gIAsIz4gvbEqJB2nzgLAZlEn'

  function getSignature(e) {
    e.preventDefault();
    

    const timestamp = new Date().getTime() - 30000
    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64')
    const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64')
    const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64')
    startMeeting(signature)
    // fetch(signatureEndpoint, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     meetingNumber: meetingNumber,
    //     role: role
    
    //   })
    // }).then(res => res.json())
    // .then(response => {
    //   startMeeting(response.signature)
    // }).catch(error => {
    //   console.error(error)
    // })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main>
        <h1>Zoom WebSDK Sample React</h1>

        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>
  );
}

export default App;






// import './App.css';

// import {usEffect} from 'react'









// declare var ZoomMtg

// ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.5/lib', '/av');

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();
// // loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load('en-US');
// ZoomMtg.i18n.reload('en-US');

// function App() {

//   // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
 
//   var apiKey = 'dzOf1gATQQGLMMAdBM72uQ'
//   var meetingNumber = '85960360837'
//   var role = 0
//   var leaveUrl = 'http://localhost:3000'
//   var userName = 'React'
//   var userEmail = 'thinlimbsworking@gmail.com'
//   var passWord = 'angry123bird'
// var apiSecret='VmU6tcF0kQy6gIAsIz4gvbEqJB2nzgLAZlEn'
//   function getSignature(e) {
//     e.preventDefault();

//     const crypto = require('crypto') // crypto comes with Node.js

    
    
    
//       const timestamp = new Date().getTime() - 30000
//       const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64')
//       const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64')
//       const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64')
    
//       return signature
   
   
//   }

//   function startMeeting(signature) {
//     document.getElementById('zmmtg-root').style.display = 'block'

//     ZoomMtg.init({
//       leaveUrl: leaveUrl,
//       isSupportAV: true,
//       success: (success) => {
//         console.log(success)

//         ZoomMtg.join({
//           signature: signature,
//           meetingNumber: meetingNumber,
//           userName: userName,
//           apiKey: apiKey,
//           userEmail: userEmail,
//           passWord: passWord,
//           success: (success) => {
//             console.log(success)
//           },
//           error: (error) => {
//             console.log(error)
//           }
//         })

//       },
//       error: (error) => {
//         console.log(error)
//       }
//     })
//   }



//   return (
//     <div className="App">
//       <main>
//         <h1>Zoom WebSDK Sample React</h1>

//         <button onClick={getSignature}>Join Meeting</button>
//       </main>
//     </div>
//   );
// }

// export default App;
